import styled from 'styled-components';

export const HomePromos = styled.div`
  font-family: 'Figtree', arial, sans-serif;
  font-size: 20px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.8);
  ::selection {
    color: #fff;
    background: #00a6e8;
  }
  @media (max-width: 1399px) {
    font-size: 20px;
    line-height: 32px;
  }
  @media (max-width: 767px) {
    padding: 0 35px;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const HomePromosItem = styled.div`
  display: block;
  position: relative;
  margin: 0;
  padding: 100px 80px 150px;
  text-align: center;
  @media (max-width: 1799px) {
    padding-left: 80px;
    padding-right: 80px;
  }
  @media (max-width: 1199px) {
    padding-top: 60px;
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (max-width: 991px) {
    padding-top: 40px;
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (max-width: 767px) {
    padding: 35px 0 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }
`;

export const HomePromosItemImage = styled.img`
  width: auto;
  height: 160px;
  margin: 0 0 40px 0;
  border: 0;
  vertical-align: top;
  @media (max-width: 991px) {
    height: 120px;
  }
`;
export const HomePromosItemHeader = styled.h2`
  margin: 0 0 20px 0;
  font-size: 35px;
  line-height: 40px;
  font-family: 'Adelle-Bold', sans-serif;
  position: relative;
  color: #0e4c80;
  font-weight: bold;
  @media (max-width: 1199px) {
    font-size: 26px;
    line-height: 30px;
  }
  @media (max-width: 991px) {
    font-size: 25px;
    line-height: 30px;
  }
`;

export const HomePromosItemParagraph = styled.p`
  font-size: 18px;
  line-height: 26px;
  margin: 0 0 40px 0;
  padding: 0;
  @media (max-width: 1199px) {
    margin: 0 0 30px 0;
    font-size: 16px;
    line-height: 24px;
  }
  @media (max-width: 991px) {
    margin: 0 0 30px 0;
    font-size: 16px;
    line-height: 24px;
  }
`;
export const HomePromosItemButton = styled.a`
  display: inline-block;
  position: relative;
  top: 0;
  padding: 17px 35px 13px 35px;
  margin-right: 5px 5px;
`;
